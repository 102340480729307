<template>
    <b-modal
        ref="b-modal"
        v-bind="$attrs"
        centered
        size="lg"
        :title="$t('teams.edit.create_title')"
        title-tag="h3"
        v-on="$listeners"
    >
        <b-form
            @submit.prevent="submit"
            @keydown.enter="submit"
        >
            <b-form-row class="row-cols-2">
                <b-col
                    v-for="field in fields"
                    :key="field"
                >
                    <form-group
                        v-model="teamUser.user[field]"
                        :label="$t(`users.form.labels.${field}`)"
                        :placeholder="$t(`users.form.placeholders.${field}`)"
                        :error="_.get(teamUser.errors, `user.${field}`)"
                    />
                </b-col>
            </b-form-row>
        </b-form>

        <template #modal-footer>
            <btn-resource
                :resource="teamUser"
                class="btn-min-w btn-primary"
                @click="submit"
            >
                {{ $t('save') }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import {Team} from '@/models/Team';
import {TeamUser} from '@/models/TeamUser';
import FormGroup from '@/components/common/form/Group';
import User from '@/models/User';

export default {
    name: 'ModalCreateTeamUser',
    components: {FormGroup},
    props: {
        team: {
            type: Team,
            required: true,
        },
    },
    data() {
        return {
            teamUser: new TeamUser(
                {user: new User({first_name: '', last_name: '', email: '', federation_number: null, handicap: ''})},
                null,
                {routePrefix: this.team.getFetchURL()},
            ),
            fields: [
                'first_name',
                'last_name',
                'email',
                'federation_number',
                'handicap',
            ],
        };
    },
    methods: {
        async submit() {
            await this.teamUser.save();

            this.$emit('saved', this.teamUser);

            this.$helpers.detainFeedback(this.$refs['b-modal'].hide);
        },
    },
};
</script>
