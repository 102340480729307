<template>
    <wait-for-resource :resource="team">
        <portal to="layout-default-header">
            <div>
                <!-- Back Button -->
                <btn-icon
                    variant="primary"
                    icon="arrow-left"
                    class="mb-4"
                    @click="$router.back()"
                />

                <b-row align-v="end">
                    <b-col class="mb-3 mb-sm-0">
                        <h1 class="mb-0">
                            {{ team.$.name }}
                        </h1>
                    </b-col>

                    <b-col class="text-right">
                        <!-- Edit team's general info. -->
                        <b-btn
                            v-b-modal="'modal-edit-team'"
                            class="btn-min-w"
                        >
                            {{ $t('teams.edit.title') }}
                        </b-btn>

                        <team-modal-save
                            id="modal-edit-team"
                            :team="team"
                        />
                    </b-col>
                </b-row>
            </div>
        </portal>

        <b-row
            align-v="end"
            class="mb-4"
        >
            <b-col class="mb-3 mb-sm-0">
                <h3 class="mb-0">
                    {{ $t('common.words.members') }}
                </h3>
            </b-col>

            <b-col class="text-right">
                <!-- Edit team's members. -->
                <btn-resource
                    class="btn-primary btn-min-w"
                    :resource="team"
                    @click="saveMembers()"
                >
                    {{ $t('save') }}
                </btn-resource>
            </b-col>
        </b-row>

        <team-users-table
            :team-users="team.team_users"
            :custom-columns="tableColumns"
            :allow-fetch="false"
        >
            <!-- Autocomplete component to search and add user to the team. -->
            <template #top-row="{columns}">
                <td :colspan="columns">
                    <auto-complete
                        reset-query
                        :url="usersEndpoint"
                        label-key=""
                        :placeholder="$t('users.autocomplete.placeholder')"
                        @onClickOption="addUserToTeam($event.original)"
                    >
                        <template #default="{option}">
                            <!-- Only show user if the team doesn't have it. -->
                            <div
                                v-if="!team.team_users.has({user_id: option.original.id})"
                                class="dropdown-item py-2"
                            >
                                <ow-img
                                    class="img-table rounded-circle mr-3"
                                    :src="option.original.image"
                                />

                                <strong>{{ `${option.original.first_name} ${option.original.last_name}` }}</strong>
                            </div>
                            <div v-else />
                        </template>

                        <template #dropdown-bottom>
                            <div
                                v-b-modal="'modal-create-team-user'"
                                class="dropdown-item py-2"
                            >
                                <btn class="btn-secondary btn-square mr-2">
                                    <i class="fa fa-plus" />
                                </btn>

                                {{ $t('teams.edit.create_player') }}
                            </div>
                        </template>
                    </auto-complete>
                </td>
            </template>

            <!-- Role Column (Editable via dropdown) -->
            <template #cell(roles)="{item}">
                <input-dd-role
                    v-model="item.roles"
                    variant="light"
                    size="sm"
                />
            </template>

            <!-- Actions Column (Remove Button) -->
            <template #cell(actions)="{item}">
                <btn-icon @click="team.team_users.remove(item)" />
            </template>
        </team-users-table>

        <modal-create-team-user
            id="modal-create-team-user"
            :team="team"
            @saved="addTeamUserToTeam($event)"
        />
    </wait-for-resource>
</template>

<script>
import User from '@/models/User';
import {Team} from '@/models/Team';
import TeamModalSave from '@/components/teams/ModalSave';
import ModalCreateTeamUser from '@/components/teams/ModalCreateTeamUser';
import TeamUsersTable from '@/components/teamUsers/Table';
import InputDdRole from '@/components/common/form/DropdownRole';
import AutoComplete from '@/components/common/form/AutoComplete';

export default {
    name: 'TeamEdit',
    components: {TeamUsersTable, AutoComplete, TeamModalSave, ModalCreateTeamUser, InputDdRole},
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            usersEndpoint: User.getEndpoint({searchFields: User.getSearchFields(), show_self: true, withGuests: true}),
            team: new Team({slug: this.slug}),
            tableColumns: {
                'index': {
                    hide: true,
                },
                'team_match_schedules_score': {
                    hide: true,
                },
                'team_match_schedules_played_count': {
                    hide: true,
                },
                'team_match_schedules_win_count': {
                    hide: true,
                },
                'team_match_schedules_draw_count': {
                    hide: true,
                },
                'team_match_schedules_lose_count': {
                    hide: true,
                },
                'match_winning_score': {
                    hide: true,
                },
                'user.handicap': {
                    hide: true,
                },
                'user.first_name': {
                    extra: 'user.email',
                },
                'roles': {
                    label: this.$t('common.inputs.role.label'),
                    sortable: true,
                },
                'actions': {
                    label: '',
                    class: 'w-auto text-right',
                },
            },
        };
    },
    watch: {
        // If the team's slug is changed, update the page route.
        'team.slug': function() {
            this.$router.replace(this.team.getLocation('edit'));
        },
    },
    async created() {
        await this.team.fetch();
    },
    methods: {
        addUserToTeam(user) {
            this.team.team_users.add({
                user_id: user.id,
                user,
            });
        },
        addTeamUserToTeam(teamUser) {
            teamUser.user.$.handicap = teamUser.user.handicap;

            this.team.team_users.add(teamUser);
        },
        saveMembers() {
            const data = {
                team_users: this.team.team_users.map((tu) => {
                    return {
                        user_id: tu.user_id,
                        roles: tu.roles,
                    };
                }),
            };

            this.team.save({data});
        },
    },
};
</script>
