<template>
    <b-modal
        ref="b-modal"
        v-bind="$attrs"
        centered
        size="lg"
        :title="$t(`teams.${isCreating ? 'create' : 'edit'}.title`)"
        title-tag="h3"
        v-on="$listeners"
    >
        <b-form
            @submit.prevent="save"
            @keydown.enter="save"
        >
            <b-row>
                <b-col sm="9">
                    <b-row>
                        <form-group
                            v-model="team.name"
                            class="col-8"
                            :label="$t('teams.model.name.label')"
                            :placeholder="$t('teams.model.name.placeholder')"
                            :error="team.errors.name"
                        />

                        <form-group
                            v-model="team.code"
                            class="col"
                            :label="$t('teams.model.code.label')"
                            :placeholder="$t('teams.model.code.placeholder')"
                            :error="team.errors.code"
                        />
                    </b-row>

                    <form-group
                        v-model="team.club_name"
                        :label="$t('teams.model.club_name.label')"
                        :placeholder="$t('teams.model.club_name.placeholder')"
                        :error="team.errors.club_name"
                    />
                </b-col>

                <b-col sm="3">
                    <form-group
                        :label="$t('teams.model.logo.label')"
                        class="d-flex flex-column h-100 pb-3"
                        :error="team.errors.image"
                    >
                        <template #input="{classes}">
                            <image-input
                                v-model="team.image"
                                class="flex-grow-1"
                                :class="classes"
                            />
                        </template>
                    </form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="6">
                    <form-group
                        v-model="team.address.street"
                        :label="$t('common.inputs.address.street.label')"
                        :placeholder="$t('common.inputs.address.street.placeholder')"
                        :error="_.get(team.errors, 'address.street')"
                    />
                </b-col>

                <b-col sm>
                    <form-group
                        v-model="team.address.number"
                        :label="$t('common.inputs.address.number.label')"
                        :placeholder="$t('common.inputs.address.number.placeholder')"
                        :error="_.get(team.errors, 'address.number')"
                    />
                </b-col>

                <b-col sm>
                    <form-group
                        v-model="team.address.zip_code"
                        :label="$t('common.inputs.address.zip_code.label')"
                        :placeholder="$t('common.inputs.address.zip_code.placeholder')"
                        :error="_.get(team.errors, 'address.zip_code')"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col sm>
                    <form-group
                        v-model="team.address.city"
                        :label="$t('common.inputs.address.city.label')"
                        :placeholder="$t('common.inputs.address.city.placeholder')"
                        :error="_.get(team.errors, 'address.city')"
                    />
                </b-col>

                <b-col sm>
                    <form-group
                        v-model="team.address.country"
                        :label="$t('common.inputs.address.country.label')"
                        :placeholder="$t('common.inputs.address.country.placeholder')"
                        :error="_.get(team.errors, 'address.country')"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col sm>
                    <form-group
                        v-model="team.phone"
                        :label="$t('common.inputs.phone.label')"
                        :placeholder="$t('common.inputs.phone.placeholder')"
                        :error="team.errors.phone"
                    />
                </b-col>

                <b-col sm>
                    <form-group
                        v-model="team.website"
                        :label="$t('common.inputs.website.label')"
                        :placeholder="$t('common.inputs.website.placeholder')"
                        :error="team.errors.website"
                    />
                </b-col>
            </b-row>

            <!-- Show team captain input only if we're creating a new team. -->
            <b-row v-if="isCreating">
                <b-col sm>
                    <form-group
                        :label="$t('teams.model.captain.label')"
                        :error="_.get(team.errors, 'team_users.0.user_id')"
                    >
                        <template #input="{classes}">
                            <auto-complete
                                v-slot="{option}"
                                v-model="team.team_users.first().user_id"
                                :input-class="classes"
                                label-key="username"
                                :placeholder="$t('teams.model.captain.placeholder')"
                                :url="usersEndpoint"
                            >
                                <div class="dropdown-item">
                                    {{ userOptionToString(option) }}
                                </div>
                            </auto-complete>
                        </template>
                    </form-group>
                </b-col>
            </b-row>
        </b-form>

        <template #modal-footer>
            <btn-resource
                :resource="team"
                class="btn-min-w btn-primary"
                @click="save()"
            >
                {{ $t('save') }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import {Address} from '@/models/Address';
import User from '@/models/User';
import {Team} from '@/models/Team';
import FormGroup from '@/components/common/form/Group';
import ImageInput from '@/components/common/form/ImageInput';
import AutoComplete from '@/components/common/form/AutoComplete';

export default {
    name: 'TeamsModalSave',
    components: {AutoComplete, FormGroup, ImageInput},
    props: {
        /**
         * The new team shall have 1 team captain.
         */
        team: {
            type: Team,
            default: () => new Team({
                address: new Address(),
                team_users: [{
                    roles: ['admin'],
                }],
            }),
        },
    },
    data() {
        return {
            usersEndpoint: User.getEndpoint({searchFields: User.getSearchFields(), show_self: true}),
        };
    },
    computed: {
        /**
         * If the team already has an identifier, we are editing that team.
         * Else, we are creating a new team.
         */
        isCreating() {
            return !this.team.identifier();
        },
    },
    methods: {
        async save() {
            try {
                await this.team.save();

                this.$emit('saved', this.team);

                if (this.isCreating) {
                    this.$helpers.detainFeedback(this.$refs['b-modal'].hide);
                }
            } catch (error) {}
        },

        /**
         * Returns the string representation of the user option.
         */
        userOptionToString(option) {
            return `
                ${option.label}
                (${option.original.first_name} ${option.original.last_name})
            `;
        },
    },
};
</script>
