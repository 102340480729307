<template>
    <input-dd
        v-bind="$attrs"
        :value="value"
        :items="roles"
        :placeholder="$t('enums.role.user')"
        @input="$emit('input', $event)"
    />
</template>

<script>
import InputDd from './Dropdown';
import Role from '@/library/enums/Role';

export default {
    name: 'InputDropdownRole',
    components: {InputDd},
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            /**
             * An array of role objects to be used as the dropdown items.
             */
            roles: [
                {value: [], label: Role.translate('user')},
                ...Role.objects.map(o =>
                    ({value: [o.key], label: o.translation}),
                ),
            ],
        };
    },
};
</script>
