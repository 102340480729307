<template>
    <div
        class="form-control d-flex align-items-center justify-content-center"
        :class="{'cursor-pointer': !readonly}"
        @click="clickInput"
    >
        <!-- Image preview. -->
        <div
            v-if="!!preview"
            class="img-square img-preview"
            :style="{'background-image': `url(${preview})`}"
        />

        <!-- Button as CTA placeholder. -->
        <btn
            v-else
            class="btn-square btn-secondary"
            type="button"
        >
            <i class="fa fa-plus" />
        </btn>

        <!-- Hidden input element to handle image upload. -->
        <template v-if="!readonly">
            <input
                ref="input"
                accept="image/*"
                hidden
                type="file"
                @change="change"
            >
        </template>
    </div>
</template>

<script>
export default {
    name: 'ImageInput',
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Object],
            default: undefined,
        },
        valueKey: {
            type: String,
            default: '210',
        },
    },
    computed: {
        /**
         * @returns {string|undefined} The preview image.
         */
        preview() {
            if (this.value) {
                if (typeof this.value === 'object') {
                    return this._.get(this.value, this.valueKey);
                }

                return this.value;
            }

            return undefined;
        },
    },
    methods: {
        clickInput() {
            if (this.readonly) return;

            this.$refs.input.click();
        },
        change(e) {
            const newImage = e.target.files[0];

            if (newImage) {
                let reader = new FileReader;

                reader.onload = (e) => {
                    this.$emit('input', e.target.result);
                };

                reader.readAsDataURL(newImage);
            }
        },
    },
};
</script>

<style lang="scss">
.img-preview {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
