var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wait-for-resource',{attrs:{"resource":_vm.team}},[_c('portal',{attrs:{"to":"layout-default-header"}},[_c('div',[_c('btn-icon',{staticClass:"mb-4",attrs:{"variant":"primary","icon":"arrow-left"},on:{"click":function($event){return _vm.$router.back()}}}),_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"mb-3 mb-sm-0"},[_c('h1',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.team.$.name)+" ")])]),_c('b-col',{staticClass:"text-right"},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-edit-team'),expression:"'modal-edit-team'"}],staticClass:"btn-min-w"},[_vm._v(" "+_vm._s(_vm.$t('teams.edit.title'))+" ")]),_c('team-modal-save',{attrs:{"id":"modal-edit-team","team":_vm.team}})],1)],1)],1)]),_c('b-row',{staticClass:"mb-4",attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"mb-3 mb-sm-0"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.words.members'))+" ")])]),_c('b-col',{staticClass:"text-right"},[_c('btn-resource',{staticClass:"btn-primary btn-min-w",attrs:{"resource":_vm.team},on:{"click":function($event){return _vm.saveMembers()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1),_c('team-users-table',{attrs:{"team-users":_vm.team.team_users,"custom-columns":_vm.tableColumns,"allow-fetch":false},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var columns = ref.columns;
return [_c('td',{attrs:{"colspan":columns}},[_c('auto-complete',{attrs:{"reset-query":"","url":_vm.usersEndpoint,"label-key":"","placeholder":_vm.$t('users.autocomplete.placeholder')},on:{"onClickOption":function($event){return _vm.addUserToTeam($event.original)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [(!_vm.team.team_users.has({user_id: option.original.id}))?_c('div',{staticClass:"dropdown-item py-2"},[_c('ow-img',{staticClass:"img-table rounded-circle mr-3",attrs:{"src":option.original.image}}),_c('strong',[_vm._v(_vm._s(((option.original.first_name) + " " + (option.original.last_name))))])],1):_c('div')]}},{key:"dropdown-bottom",fn:function(){return [_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-create-team-user'),expression:"'modal-create-team-user'"}],staticClass:"dropdown-item py-2"},[_c('btn',{staticClass:"btn-secondary btn-square mr-2"},[_c('i',{staticClass:"fa fa-plus"})]),_vm._v(" "+_vm._s(_vm.$t('teams.edit.create_player'))+" ")],1)]},proxy:true}],null,true)})],1)]}},{key:"cell(roles)",fn:function(ref){
var item = ref.item;
return [_c('input-dd-role',{attrs:{"variant":"light","size":"sm"},model:{value:(item.roles),callback:function ($$v) {_vm.$set(item, "roles", $$v)},expression:"item.roles"}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('btn-icon',{on:{"click":function($event){return _vm.team.team_users.remove(item)}}})]}}])}),_c('modal-create-team-user',{attrs:{"id":"modal-create-team-user","team":_vm.team},on:{"saved":function($event){return _vm.addTeamUserToTeam($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }